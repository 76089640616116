import sdpTransform from 'sdp-transform';

/**
 * RTCSessionDescription implementation.
 */
export default class RTCSessionDescription {
    /**
     * RTCSessionDescription constructor.
     * @param {Object} [data]
     * @param {String} [data.type] - 'offer' / 'answer'.
     * @param {String} [data.sdp] - SDP string.
     * @param {Object} [data._sdpObject] - SDP object generated by the
     * sdp-transform library.
     */
    constructor(data) {
        // @type {String}
        this._sdp = null;

        // @type {Object}
        this._sdpObject = null;

        // @type {String}
        this._type = null;

        switch (data.type) {
        case 'offer':
            break;
        case 'answer':
            break;
        default:
            throw new TypeError(`invalid type "${data.type}"`);
        }

        this._type = data.type;

        if (typeof data.sdp === 'string') {
            this._sdp = data.sdp;
            try {
                this._sdpObject = sdpTransform.parse(data.sdp);
            } catch (error) {
                throw new Error(`invalid sdp: ${error}`);
            }
        } else if (typeof data._sdpObject === 'object') {
            this._sdpObject = data._sdpObject;
            try {
                this._sdp = sdpTransform.write(data._sdpObject);
            } catch (error) {
                throw new Error(`invalid sdp object: ${error}`);
            }
        } else {
            throw new TypeError('invalid sdp or _sdpObject');
        }
    }

    /**
     * Get sdp field.
     * @return {String}
     */
    get sdp() {
        return this._sdp;
    }

    /**
     * Set sdp field.
     * NOTE: This is not allowed per spec, but lib-jitsi-meet uses it.
     * @param {String} sdp
     */
    set sdp(sdp) {
        try {
            this._sdpObject = sdpTransform.parse(sdp);
        } catch (error) {
            throw new Error(`invalid sdp: ${error}`);
        }

        this._sdp = sdp;
    }

    /**
     * Gets the internal sdp object.
     * @return {Object}
     * @private
     */
    get sdpObject() {
        return this._sdpObject;
    }

    /**
     * Get type field.
     * @return {String}
     */
    get type() {
        return this._type;
    }

    /**
     * Returns an object with type and sdp fields.
     * @return {Object}
     */
    toJSON() {
        return {
            sdp: this._sdp,
            type: this._type
        };
    }
}
